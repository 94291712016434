@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* ==============
  General
===================*/
html {
  scroll-behavior: smooth;
}

body {
  background: #f0f2f5 !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #354558;
  font-size: 13px;
  scroll-behavior: smooth;
  font-weight: 500 !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #ffffff !important;
  background: #30419b !important;
  border-color: #f0f0f0;
  border-bottom: 1px solid #fff;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #30419b !important;
}
.dashboard-wrapper
{
  padding: 25px;
}
.ant-list-items
{
  background: white;
}
.ant-layout-header
{
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background: #fff !important;
}
.txt-color
{
  color: black;
}
.search-header .ant-input-affix-wrapper
{
  width:40% !important;
}
@media (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}

.ant-btn-primary {
  background-color: #30419b !important;
  border: 1px solid #30419b !important;
}

.mainlayout {
  margin-left: 200px;
}
.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #30419b !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-content {
  padding: 15px;
}

.ant-list-bordered .ant-list-item {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.ant-menu-inline-collapsed-tooltip { display: none; }
@media only screen and (max-width: 767px) {
  .mainlayout {
    margin-left: 0px;
  }
  .ant-layout-sider {
    z-index: 1;
  }
.dashboard-wrapper
{
  padding:0;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: none !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ant-layout-sider-zero-width-trigger {
  top:11px !important;
  background-color: #efb527 !important;
}
a {
  color: #ffffff !important;
}
.ant-dropdown-menu-item > a
{
  color: rgba(0, 0, 0, 0.65) !important;
}
.dash-time {
  margin-right: 15px;
  font-weight: bold;
  font-size: 16px;
}
.ant-radio-wrapper
{
  font-size: 16px !important;
}
.ant-radio-group {
  
  margin: 0 0 25px !important;
}
.ant-layout-header
{
   box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background: #30419b !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #30419b !important;
}
.txt-color
{
  color:white;
}
.search-header .ant-input-affix-wrapper
{
  width:100% !important;
}
.h5, h5 {
  font-size: small !important;
}
time
{
  font-size: 15px !important;
}
}


.logomenu img.logoimg {
  height: 52px;
  margin: auto;
  margin-top: 15px;
}

.login-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("../img/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.loginform {
  width: 95%;
  max-width: 400px;
}
.loginform img {
  margin: auto;
  margin-bottom: 10px;
}
.ant-list-item-action .ant-btn-circle,
.ant-list-item-action .ant-btn-circle-outline {
  min-width: 25px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
  font-size: 8px !important;
  width: 25px !important;
  height: 25px !important;
}
.ant-list-item-action .ant-list-item-action-split {
  display: none;
}
.dash-header {
  padding: 15px;
  margin-bottom: 15px;
}
.dash-time {
  margin-right: 15px;
  font-weight: bold;
}
.search-header {
  text-align: right;
  padding: 0 0 15px 0;
}
#basic .ant-form-item
{
  margin-bottom: 10px !important;
}
#support .ant-form-item
{
  margin-bottom: 24px !important;
}
#support .ant-form-item-with-help {
  margin-bottom: 24px !important;
}
.ant-tabs-nav-scroll {
  border-bottom: 1px solid #eae7e7;
}
span.ant-typography
{
  color:#637BFF;

}
.text-danger {
  color: #ff0500;
}
.login-title h4
{
  margin-bottom: 30px;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 1px solid #f0f0f000 !important;
}
.ant-menu
{
  background: transparent !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #efb527 !important;
}